'use client'
import { createRef, MouseEvent } from "react";
import styles from "./index.module.css";
import Button from "../components/button/button";
import Subscribe from "../components/subscribe/subscribe";
import classnames from "classnames";
import { raleway } from 'fonts';

export default function Home() {
  const subscribeForm = createRef<HTMLInputElement>();

  const jumpToWaitlistForm = (e:MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (subscribeForm.current) {
      subscribeForm.current.scrollIntoView();
      subscribeForm.current.focus();
    }
  };

  return (
    <main>
      <div className={styles.hero}>
        <div className={styles.heroImage}>
          <h1>
            Meet Virna,<br />
            the virtual parenting assistant that grows with your family.
          </h1>
          <p className={raleway.className}>We&apos;re creating a way to provide you with personalized support and guidance as you navigate the joys and challenges of parenthood.</p>
          <Button onClick={jumpToWaitlistForm}>Join our waitlist</Button>
        </div>
      </div>
      <div className={styles.functions}>
        <div className={styles.function}>
          <h3 className={styles.assist}>Assist your child&apos;s development</h3>
          <p>Get tips to prepare for upcoming milestones and overcome common challenges.</p>
        </div>
        <div className={styles.function}>
          <h3 className={styles.time}>Reclaim your time</h3>
          <p>Virna provides you with personalized guidance and support when you need it. No more scrambling or spending hours researching.</p>
        </div>
        <div className={styles.function}>
          <h3 className={styles.answer}>Answer your parenting questions</h3>
          <p>Not sleeping? Starting solids? Potty training? Virna is here to help.</p>
        </div>
      </div>
      <div className={styles.benefits}>
        <h2>The benefits of using Virna</h2>
        <div className={classnames(styles.benefit, styles.proactive)}>
          <h3>Proactive</h3>
          <p>Notifications help you stay ahead by getting personalized support when you need it.</p>
        </div>
        <div className={classnames(styles.benefit, styles.easy)}>
          <h3>Easy to use</h3>
          <p>Spend less time researching and tracking data. Using Virna is as easy as talking to a friend.</p>
        </div>
        <div className={classnames(styles.benefit, styles.supportive)}>
          <h3>Supportive</h3>
          <p>When you&apos;re feeling overwhelmed or stressed, Virna can offer judgement-free encouragement.</p>
        </div>
      </div>
      <div className={styles.how}>
        <h2>How does Virna work?</h2>
        <p>Virna is powered by artificial intelligence, so it can provide you with personalized guidance and support that is tailored to your specific needs.</p>
        <p>By constantly learning and updating, you can be sure that you are always getting the most relevant, up-to-date information and advice.</p>
        <p>Virna is backed by a team of moms and security experts, so you can be sure that your data privacy is always prioritized.</p>
      </div>
      <div className={styles.join} id="join">
        <div>
          <h2>Join our waitlist</h2>
          <p>Be the first to know when we launch!</p>
          <Subscribe ref={subscribeForm} className={styles.subscribe} />
        </div>
      </div>
    </main>
  );
}
